import apiClient from "../utils/apiClient.js";

export var orgSupportMixin = {

	data: function () { return {
        supportMessage: "",
          supportEmail: "",
          supportMessageSent: "",
    } },

	methods: {

		submitSupport: function () {
			var that = this;
			var vparam = getParameterByName('v');
			var spinId = that.spinStart();
			apiClient.post('/api/org/support/', {message: that.supportMessage, email: that.supportEmail || that.user.email, vparam: vparam}).then(function (response) {
				that.spinStop(spinId);
				that.supportMessageSent = that.supportMessage;
				that.supportMessage = "";
			});
		},

		submitListSupport: function () {
			var that = this;
			var spinId = that.spinStart();
			apiClient.post('/api/org/support/', {message: that.supportMessage}).then(function (response) {
				that.spinStop(spinId);
				that.supportMessageSent = that.supportMessage;
				that.supportMessage = "";
			});
		}

	}

}