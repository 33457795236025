<template>
<div>
    <nav class='navbar navbar-default navbar-fixed-top' id="header" v-cloak>
  <div class='container'>
    <div class='navbar-header'>
      <button type='button' class='navbar-toggle collapsed' data-toggle='collapse' data-target='#navbar' aria-expanded='false' aria-controls='navbar'>
        <span class='sr-only'>Toggle navigation</span>
        <span class='icon-bar'></span>
        <span class='icon-bar'></span>
        <span class='icon-bar'></span>
      </button>
      <a target="_blank" class='navbar-brand' href="/home">
        <img src="/ivgcs/img/logo-original.png" alt="InitialView" style="width: 218px;padding-top:17px;">
      </a>
    </div>
    <div id='navbar' class='collapse navbar-collapse'>
      <ul class="nav navbar-nav">
      </ul>
      <ul class='nav navbar-nav navbar-right'>
        <li class="dropdown" v-show="userFullName">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{userFullName}} <span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li class=''><a v-on:click.prevent="user_logout()">Log Out</a></li>
              </ul>
        </li>
        <li>
            <a :href="env.serverAddr + '/about/'">About</a>
        </li>

        <li>
            <a :href="env.serverAddr + '/faqs/'">FAQs</a>
        </li>
        <li>
            <a :href="env.serverAddr + '/blog/'">Blog</a>
        </li>

      </ul>
    </div>
  </div>
</nav>
</div>
</template>

<script>
import ivbus from "@/utils/ivbus.js";
import env from "@/utils/env.js";

export default {
    data() {
        return {
            env: env,
            userFullName: ''
        }
    },
    mounted: function () {
        var that = this;
        ivbus.getFlagSticky('user', function (user) {
            if (user) {
                that.userFullName = user.last_name + ", " + user.first_name;
            } else {
                that.userFullName = "";
            }
        });
    },
    methods: {
        // TODO: replace this with the ivUserAccountMixin user_logout
        user_logout: function () {
            sessionStorage.removeItem("apitoken");
            location = '/login/';
            }
        }
    }
</script>